import { styled } from "@mui/material";

import logo from "../assets/Logo/360Live-460.png";

const Container = styled("div")({
  width: "100vw",
  height: "100vh",
  overflow: "hidden",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const Logo = styled("img")({
  width: "30%",
});

const LandingPage = () => {
  return (
    <Container>
      <Logo
        sx={{
          width: {
            xs: "60%",
            lg: "30%",
          },
        }}
        src={logo}
        alt="360Live logo"
      />
    </Container>
  );
};

export default LandingPage;
