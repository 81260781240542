import { Box, Paper, Typography } from "@mui/material";

import logo from "../assets/Logo/360Live-460.png";
import theme from "../theme";

const Layout = ({ title, children, footer, classNames, paperSx, showTitle = true, showLogo = true, ...props }) => {
  return (
    <Box
      sx={{
        bgcolor: "background.default",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: "100vh",
      }}
      classNames={classNames}
      {...props}
    >
      {showLogo && (
        <Box paddingTop="40px">
          <img src={logo} alt="360Live logo" style={{ textAlign: "center", width: "126px" }} />
        </Box>
      )}

      <Paper
        sx={{
          height: "auto",
          width: "390px",
          borderRadius: "8px",
          padding: theme.spacing(4),
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          boxShadow: "0px 4px 8px rgba(9, 28, 43, 0.08)",
          ...paperSx,
        }}
      >
        {showTitle && (
          <Box mb={2} display="flex" alignItems="center">
            <Typography fontWeight={600} fontSize="24px">
              {title}
            </Typography>
          </Box>
        )}

        {children}
      </Paper>

      {footer}
    </Box>
  );
};

export default Layout;
