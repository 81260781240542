import { useEffect, useState } from "react";

import { Box, Button, Typography } from "@mui/material";
import { applyActionCode, getAuth } from "firebase/auth";
import { useHistory } from "react-router-dom";

import { firebaseApp } from "../config/firebase";
import useQueryParam from "../hooks/useQueryParam";
import Layout from "./_Layout";

export default function VerifyEmailPage() {
  const query = useQueryParam();
  const continueUrl = query.get("continueUrl");
  const actionCode = query.get("oobCode");
  const auth = getAuth(firebaseApp);
  const history = useHistory();

  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (!actionCode) {
      history.replace("/");
    }
  }, [actionCode, history]);

  const handleVerifyEmail = () => {
    const handleVerifySuccess = _res => {
      query.set("mode", "verifyEmailComplete");

      if (continueUrl) {
        history.replace({
          search: query.toString(),
          state: { continueUrl },
        });
      } else {
        history.replace("/");
      }
    };

    const handleVerifyError = _error => {
      setIsError(true);
    };

    applyActionCode(auth, actionCode).then(handleVerifySuccess).catch(handleVerifyError);
  };

  return (
    <Layout fullScreen title="Verify Your Email Address" showLogo={false} paperSx={{ width: 513, height: 334 }}>
      <Box
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        width="100%"
        marginY={2}
        px={3}
        flexDirection="column"
      >
        <Typography fontWeight={400} fontSize="16px" color="rgba(9, 28, 43, 0.8)" textAlign="center" width={300}>
          Please click on the Verify Email <br /> button below to able start using our website.
        </Typography>

        <Box my={3} />

        {isError && (
          <Typography color="error.light" textAlign="center">
            Unable to verify your email
          </Typography>
        )}

        <Button
          disableRipple
          variant="contained"
          color="primary"
          type="submit"
          sx={{ width: 200 }}
          disabled={isError}
          onClick={handleVerifyEmail}
        >
          Verify Email
        </Button>
      </Box>
    </Layout>
  );
}
