import { createTheme } from "@mui/material";

const primaryColor = "#0088ED";

const theme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    background: {
      default: "#F5F5F5",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            backgroundColor: primaryColor,
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: "#fff",
        },
      },
    },
  },
});

export default theme;
