import { useEffect } from "react";

import { Box, Button, Typography } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";

import CheckedIcon from "../assets/Icons/CheckedIcon";
import Layout from "./_Layout";

const ResetPasswordCompletePage = () => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (!location?.state?.continueUrl) {
      history.replace("/"); // push to landing
    }
  }, [history, location]);

  const handleRedirectToSignIn = () => {
    window.location.href = location?.state?.continueUrl;
  };

  return (
    <Layout fullScreen showLogo={false} showTitle={false} paperSx={{ width: 384, height: 318 }}>
      <Box display="flex" alignItems="center" width="100%" px={3} flexDirection="column">
        <CheckedIcon />
        <Box py={1} />
        <Typography fontWeight={400} fontSize="16px" color="rgba(9, 28, 43, 0.5)" textAlign="center">
          Your email has been verified. Please sign in.
        </Typography>

        <Box py={2} />

        <Button fullWidth disableRipple variant="contained" color="primary" onClick={handleRedirectToSignIn}>
          Sign in
        </Button>

        <Box py={1} />
      </Box>
    </Layout>
  );
};

export default ResetPasswordCompletePage;
